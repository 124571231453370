<template>
    <div>
        <span
            v-if="!isConnected"
            href="javascript:void(0)"
        >
            <endpoint-fetcher
                v-if="loginEndpoint"
                :endpoint="loginEndpoint"
                v-model:fetchedData="connectURL"
                style="min-height: 0"
            >
                <div class="d-flex align-items-center justify-content-end">
                    <i
                        class="fa fa-exclamation text-danger mr-1"
                        aria-hidden="true"
                        v-if="lostConnection && showExclamation"
                    ></i>
                    <a
                        :class="connectClass"
                        :href="connectURL"
                    >
                        <small class="d-flex text-right">{{ connectText }}</small>
                    </a>
                </div>
            </endpoint-fetcher>
        </span>
        <small
            v-else
            class="text-green"
            >{{ $t("connection.itemLabelConnected") }}</small
        >
    </div>
</template>

<script>
    import EndpointFetcher from "@/components/common/EndpointFetcher.vue";
    import { endpoints } from "../common/api-config.js";
    import { useMainStore } from "@/main-store";

    export default {
        name: "connect-link",
        props: {
            app: {
                type: String,
                required: true,
                validator: (value) => ["facebook", "linkedin", "instagram", "emarketing"].includes(value),
            },
            showExclamation: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            EndpointFetcher,
        },
        data() {
            return {
                connectURL: "",
                mainStore: useMainStore(),
            };
        },
        computed: {
            appState() {
                const defaultValue = { installed: false, connected: false };
                return this.mainStore[this.app] || defaultValue;
            },
            isConnected() {
                return this.appState.connected;
            },
            lostConnection() {
                return this.appState.installed && !this.appState.connected;
            },
            connectText() {
                let connectText = "";
                const { installed, connected } = this.appState;
                if (!connected) {
                    if (installed) {
                        connectText = this.$t("connection.itemLabelClickToRefresh");
                    } else {
                        connectText = this.$t("connection.itemLabelClickToConnect");
                    }
                }
                return connectText;
            },
            connectClass() {
                if (this.lostConnection) {
                    return "text-danger";
                } else {
                    return "reset-link";
                }
            },
            loginEndpoint() {
                return endpoints["profilesocial-url"](this.profileId, this.app);
            },
            profileId() {
                return window.profileData ? window.profileData.pk : "";
            },
        },
        watch: {
            connectURL(newValue) {
                window.profileData.applications[this.app].connectURL = newValue;
                this.mainStore[this.app] = { ...this.mainStore[this.app], connectURL: newValue };
            },
        },
    };
</script>
