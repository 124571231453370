export const connectionConfigValues = {
    facebook: {
        id: "facebook",
        provider: "fbpage",
        page_id: "page_id",
        name: "Facebook",
        icon: "fa-facebook-square",
        order: 1,
        hashtagEnabled: true,
        image: "https://assets.branchup.travel/BranchupApp/images/placeholders/facebook.jpg",
        stats: [
            {
                label: "common.reach",
                field: "post_impressions_unique",
            },
            {
                label: "common.engagement",
                field: "post_engaged_users",
            },
            {
                label: "common.clicks",
                field: "post_clicks",
            },
        ],
    },
    instagram: {
        id: "instagram",
        provider: "instagram",
        page_id: "page_id",
        name: "Instagram",
        icon: "fa-instagram",
        plan: "pro",
        order: 2,
        hashtagEnabled: true,
        image: "https://assets.branchup.travel/BranchupApp/images/placeholders/instagram.jpg",
        needsPro: true,
        stats: [
            {
                label: "common.reach",
                field: "post_impressions_unique",
            },
            {
                label: "common.engagement",
                field: "post_engaged_users",
            },
            {
                label: "common.clicks",
                field: "post_clicks",
            },
        ],
    },
    linkedin: {
        id: "linkedin",
        provider: "linkedin",
        page_id: "organization_urn",
        name: "LinkedIn",
        icon: "fa-linkedin-square",
        order: 3,
        hashtagEnabled: true,
        image: "https://assets.branchup.travel/BranchupApp/images/placeholders/linkedin.jpg",
        stats: [
            {
                label: "common.likes",
                field: "post_likes_summary",
            },
            {
                label: "common.comments",
                field: "post_comments_summary",
            },
            {
                label: "common.clicks",
                field: "post_clicks_summary",
            },
        ],
    },
    emarketing: {
        id: "emarketing",
        name: "Client Newsletters",
        icon: "fa-envelope-o",
        order: 4,
        image: "https://assets.branchup.travel/BranchupApp/images/placeholders/emarketing.jpg",
    },
};
