<script>
    import { connectionConfigValues } from "@/components/apps-connection-config.js";
    import { postingStatus } from "@/components/post/post-config.js";

    export default {
        name: "PlaceholderFetcher",
        emits: ["add-calendar-event", "remove-placeholders"],
        props: {
            selectedApps: {
                type: Array,
                required: true,
            },
            dateFrom: {
                type: Object,
                required: true,
            },
        },
        methods: {
            generatePlaceholders() {
                const appsList = Object.keys(connectionConfigValues);
                const notEnabledApps = appsList.filter(
                    (app) =>
                        window.profileData.applications[app].is_enabled == false &&
                        window.profileData.applications[app].visible == true &&
                        this.$props.selectedApps.includes(app) // Show only toogled-on apps
                );

                const placeholderDates = {
                    facebook: [
                        // Monday, Tuesday, Wednesday, Thursday, Friday @ 1:30 PM Eastern time
                        this.dateFrom.clone().day(1).hour(13).minute(30).tz("America/Toronto").format(),
                        this.dateFrom.clone().day(2).hour(13).minute(30).tz("America/Toronto").format(),
                        this.dateFrom.clone().day(3).hour(13).minute(30).tz("America/Toronto").format(),
                        this.dateFrom.clone().day(4).hour(13).minute(30).tz("America/Toronto").format(),
                        this.dateFrom.clone().day(5).hour(13).minute(30).tz("America/Toronto").format(),
                    ],
                    instagram: [
                        // Monday, Wednesday, Friday @ 2:00 PM Eastern time
                        this.dateFrom.clone().day(1).hour(14).minute(0).tz("America/Toronto").format(),
                        this.dateFrom.clone().day(3).hour(14).minute(0).tz("America/Toronto").format(),
                        this.dateFrom.clone().day(5).hour(14).minute(0).tz("America/Toronto").format(),
                    ],
                    linkedin: [
                        // Tuesday, Thursday @ 2:30 PM Eastern time
                        this.dateFrom.clone().day(2).hour(14).minute(30).tz("America/Toronto").format(),
                        this.dateFrom.clone().day(4).hour(14).minute(30).tz("America/Toronto").format(),
                    ],
                    emarketing: [
                        // every second Thursday @ 1:00 PM Eastern time
                        this.dateFrom.clone().day(4).hour(13).minute(0).tz("America/Toronto").format(),
                    ],
                };

                this.$emit("remove-placeholders", true);

                let id = 0;
                for (const app of notEnabledApps) {
                    for (const fire_at of placeholderDates[app]) {
                        id += 1;
                        const post = this.getPlaceholderItem(id, fire_at, app);
                        const event = {
                            id: post.id,
                            title: post.message,
                            start: post.fire_at,
                            editable: false,
                            extendedProps: {
                                app: app,
                                post: post,
                                enabled: false,
                                imageUrl: post.image,
                                highligthed: false,
                            },
                        };
                        this.$emit("add-calendar-event", event);
                    }
                }
            },
            getPlaceholderItem(id, fire_at, app) {
                switch (app) {
                    case "facebook":
                        return {
                            id: id,
                            message: this.$t("connection.marketingFacebook"),
                            image: connectionConfigValues[app].image,
                            fire_at: fire_at,
                            status: postingStatus.POSTED,
                            type: "placeholder",
                        };
                    case "instagram":
                        return {
                            id: id,
                            message: this.$t("connection.marketingInstagram"),
                            image: connectionConfigValues[app].image,
                            fire_at: fire_at,
                            status: postingStatus.POSTED,
                            type: "placeholder",
                        };
                    case "linkedin":
                        return {
                            id: id,
                            message: this.$t("connection.marketingLinkedin"),
                            image: connectionConfigValues[app].image,
                            fire_at: fire_at,
                            status: postingStatus.POSTED,
                            type: "placeholder",
                        };
                    case "emarketing":
                        return {
                            id: id,
                            message: this.$t("connection.marketingEmarketing"),
                            image: connectionConfigValues[app].image,
                            fire_at: fire_at,
                            status: postingStatus.POSTED,
                            type: "placeholder",
                        };
                    default:
                        return null;
                }
            },
        },
        watch: {
            selectedApps() {
                this.generatePlaceholders();
            },
            dateFrom() {
                this.generatePlaceholders();
            },
        },
    };
</script>
